import React, { useState } from "react";
import { Card, View, Text, Spacer } from "ollui";
import { Section } from "./section";
import Image from "../components/PreviewCompatibleImage";
// import screenshot from "../img/screenshot-page.png";

export const Download = () => {
  const [screenshotIndex, setScreenshotIndex] = useState(0);

  return (
    <Section
      heading="Download"
      subHeading="Get your version of the taltrast app"
      id="download"
      extend={sectionCSS}
    >
      <View extend={rootCSS}>
        <View extend={buttonsCSS}>
          <View
            as="a"
            href="https://apps.apple.com/be/app/taltrast/id1573167281"
            target="_blank"
            extend={buttonCSS}
          >
            <Image
              imageInfo={{
                image: "img/app_store.png",
              }}
              extend={buttonImageCSS}
              objectFit="contain"
            />
          </View>
          <Spacer size={4} />
          <View
            as="a"
            href="https://play.google.com/store/apps/details?id=com.app.taltrast"
            target="_blank"
            extend={buttonCSS}
          >
            <Image
              imageInfo={{
                image: "img/play_store.png",
              }}
              extend={buttonImageCSS}
              objectFit="contain"
            />
          </View>
        </View>
      </View>
    </Section>
  );
};

const sectionCSS = ({ theme: { breakpoints, grid } }) => ({
  // paddingTop: 45 * grid,
  zIndex: 0,
});

const rootCSS = ({ theme: { breakpoints, grid } }) => ({
  display: "flex",
  // gridTemplateColumns: "repeat(3, 1fr)",
  // gridGap: 8 * grid,
  flexDirection: "column",
  // background: "orange",
  justifyContent: "center",

  alignItems: "center",

  [breakpoints.onlyS]: {
    flexDirection: "column-reverse",
    // gridTemplateColumns: "1fr",
    // gridGap: 6 * grid,
  },
});

const buttonCSS = () => ({
  position: "relative",
  width: 175,
  cursor: "pointer",
  transition: "all 150ms ease-in-out",
  "&:hover": {
    opacity: 0.4,
  },
});

const buttonImageCSS = () => ({
  width: "100%",
  pointerEvents: "none",
});

const buttonsCSS = ({ theme: { breakpoints, grid } }) => ({
  position: "relative",
  display: "flex",
  [breakpoints.onlyS]: {
    flexDirection: "column",
    // gridTemplateColumns: "1fr",
    // gridGap: 6 * grid,
  },
});

const noHoverCSS = () => ({
  cursor: "initial",

  "&:hover": {
    opacity: 1,
  },
});
