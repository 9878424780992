import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { Card, View, Text, Spacer } from "ollui";
import { RiArrowDropRightLine, RiArrowDropLeftLine } from "react-icons/ri";
import { Feature } from "./features";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const FeaturesMobileCarousel = ({
  features,
  page,
  direction,
  setPage,
}) => {
  // const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, features.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <View extend={rootCSS}>
      <AnimatePresence initial={false} custom={direction}>
        <View
          as={motion.div}
          extend={slideCSS}
          key={page}
          src={features[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          <Feature feature={features[imageIndex]} />
        </View>
      </AnimatePresence>
      <View
        as="button"
        extend={[arrowCSS, arrowRightCSS]}
        onClick={() => paginate(1)}
      >
        <RiArrowDropRightLine size={30} />
      </View>
      <View
        as="button"
        extend={[arrowCSS, arrowLeftCSS]}
        onClick={() => paginate(-1)}
      >
        <RiArrowDropLeftLine size={30} />
      </View>
    </View>
  );
};

const rootCSS = ({ theme: { breakpoints } }) => ({
  // background: "orange",
  height: 200,
  width: "100%",
  position: "relative",

  [breakpoints.fromM]: {
    display: "none",
  },
});

const slideCSS = {
  position: "absolute",
  top: 0,
  // left: 0,
  // background: "hotpink",
  // height: 400,
};

const arrowCSS = ({ theme: { colors, grid, shadows } }) => ({
  position: "absolute",
  // top: 4 * grid,
  top: -2 * grid,
  zIndex: 1,
  width: 30,
  height: 30,
  borderRadius: "50%",
  border: "none",
  background: colors.bg,
  boxShadow: shadows.small,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const arrowRightCSS = ({ theme: { grid } }) => ({
  right: -1.5 * grid,
});

const arrowLeftCSS = ({ theme: { grid } }) => ({
  left: -1.5 * grid,
});
