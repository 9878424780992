import React, { useState, useEffect } from "react";
import {
  RiAlarmWarningLine,
  RiPinDistanceLine,
  // RiAlertLine,
  RiBarChartLine,
  RiGpsLine,
  // RiTeamLine,
  // RiVipCrownFill,
  // RiBarChartLine,
  // RiPriceTag2Line,
} from "react-icons/ri";
// import RiLockLine from "react-icons/ri/lockline";
// import RiRestTimeLine from "react-icons/lib/ri/rest-time-line";
// import RiAlertLine from "react-icons/ri";
// import RiTimerLine from "react-icons/ri/timer-line";
// import RiPlayMiniLine from "react-icons/ri/notification-badge-line";
// import RiTeamLine from "react-icons/ri/team-line";
// import RiVipCrownFill from "react-icons/ri/";
import { Card, View, Text, Spacer } from "ollui";
import { Section } from "./section";
import Image from "../components/PreviewCompatibleImage";
// import screenshot from "../img/screenshot-page.png";
import { FeaturesMobileCarousel } from "./features.mobile-carousel";

const ICON_SIZE = 40;

const features = [
  {
    icon: <RiBarChartLine size={ICON_SIZE} />,
    title: "View stats on your trips.",
    description:
      "Stats like distance travelled, countries visited and average speed etc.",
    image: "img/stats.jpg",
  },
  {
    icon: <RiGpsLine size={ICON_SIZE} />,
    title: "Monitor your vehicle's real-time location.",
    description:
      "The taltrast hub automatically sends a new location every 30s to make sure you have the most up-to-date information on your vehicle's whereabouts. While you are driving this interval goes down to 15s to ensure a smooth trip generation for your viewing pleasure.",
    image: "img/current-location.jpg",
  },
  {
    icon: <RiPinDistanceLine size={ICON_SIZE} />,
    title: "View the trips you have ventured on.",
    description:
      "Taltrast automatically generates trips that you venture on and afterwards you can view them in the app.",
    image: "img/trips.jpg",
  },
  {
    icon: <RiAlarmWarningLine size={ICON_SIZE} />,
    title: "Geofence alarm.",
    description:
      "You can easily set a Geofence alarm and customize a radius and if your vehicle travels outside this radius you immediately get notified via a push notification on your phone.",
    image: "img/geofence.jpg",
  },
  // {
  //   icon: <RiPinDistanceLine size={28} />,
  //   title: "Customizable status page for your site",
  //   description:
  //     "Get access to a customizable (logo + colour) status page that shows your site's status and history.",
  // },
  // {
  //   icon: <RiTeamLine size={28} />,
  //   title: "Unlimited team size",
  //   description: "Add as many members to your team as you need.",
  // },
  // {
  //   icon: <RiPriceTag2Line size={28} />,
  //   title: "Everything included in flat pricing",
  //   description:
  //     "You only get charged for what you use. More specifically the number of sites you use. All features are included.",
  // },
  // {
  //   icon: <RiAlertLine size={34} />,
  //   title: "",
  //   description: "",
  // },
  // {
  //   icon: <RiAlertLine size={34} />,
  //   title: "",
  //   description: "",
  // },
];

export const Features = () => {
  const [screenshotIndex, setScreenshotIndex] = useState(0);
  const [[page, direction], setPage] = useState([0, 0]);
  // const theme = useFela();
  // console.log(theme);

  useEffect(() => {
    if (page === screenshotIndex) {
      return;
    }
    const deviceWidth = window.innerWidth;
    console.log("deviceWidth", deviceWidth);
    console.log("page", page);
    const isCarousel = deviceWidth < 480;
    const index = isCarousel
      ? page < 0 || page >= features.length - 1
        ? Math.abs(page) % features.length
        : page
      : screenshotIndex;
    console.log("index", index);
    setScreenshotIndex(index);
  }, [page, screenshotIndex, setScreenshotIndex]);

  return (
    <Section
      heading="Features"
      subHeading="Why you should choose Taltrast"
      id="features"
      extend={sectionCSS}
    >
      <View extend={rootCSS}>
        <View extend={screenshotCSS}>
          <Image
            imageInfo={{
              image: features[screenshotIndex].image,
            }}
            extend={screenshotImageCSS}
            objectFit="contain"
          />
        </View>
        <FeaturesMobileCarousel
          features={features}
          page={page}
          direction={direction}
          setPage={setPage}
        />
        <View extend={featuresDesktopCSS}>
          {features.map((feature, index) => (
            <Feature
              feature={feature}
              key={feature.title}
              onMouseOver={() => setScreenshotIndex(index)}
              onClick={() => setScreenshotIndex(index)}
            />
          ))}
        </View>
      </View>
    </Section>
  );
};

export const Feature = ({ feature, ...props }) => {
  const { icon, title, description } = feature || {};
  return (
    <Card extend={featureCSS} {...props}>
      <View extend={iconCSS}>
        <View extend={iconIconCSS}>{icon}</View>
      </View>
      <View extend={textCSS}>
        <Text variant="medium" bold>
          {title}
        </Text>
        <Spacer />
        <Text extend={{ lineHeight: "1.5" }}>{description}</Text>
      </View>
    </Card>
  );
};

const sectionCSS = ({ theme: { breakpoints, grid } }) => ({
  // paddingTop: 45 * grid,
  zIndex: 0,
});

const rootCSS = ({ theme: { breakpoints, grid } }) => ({
  display: "flex",
  // gridTemplateColumns: "repeat(3, 1fr)",
  // gridGap: 8 * grid,
  // flexDirection: "column",
  // background: "orange",
  // justifyContent: "center",

  alignItems: "center",
  [breakpoints.onlyS]: {
    flexDirection: "column",
    // gridTemplateColumns: "1fr",
    // gridGap: 6 * grid,
  },
});

const screenshotCSS = ({ theme: { breakpoints } }) => ({
  width: "50%",
  height: "100%",
  maxHeight: "100vh",

  [breakpoints.onlyS]: {
    width: "100%",
    maxHeight: "80vh",
  },
});

const screenshotImageCSS = () => ({
  width: "100%",
});

const featuresDesktopCSS = ({ theme: { breakpoints, grid } }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(2, 1fr)",
  // gridGap: 5 * grid,
  // width: "100%",
  flex: 1,
  flexGrow: 1,
  height: "100%",
  // background: "hotpink",
  // maxHeight: "100vh",
  // minWidth: 350,
  // flexGrow: 0,
  [breakpoints.onlyS]: {
    display: "none",
    // gridTemplateColumns: "1fr",
    // gridGap: 6 * grid,
  },
});

const featureCSS = ({ theme: { breakpoints, grid, shadows } }) => ({
  display: "flex",
  textAlign: "left",
  width: "100%",
  // alignItems: "center",
  marginBottom: 4 * grid,
  cursor: "pointer",
  alignItems: "center",

  "&:hover": {
    // background: "red",
    boxShadow: shadows.small,
  },

  [breakpoints.onlyS]: {
    flexDirection: "column",
    // textAlign: "center",
  },
});

const iconCSS = ({ theme: { colors, breakpoints } }) => ({
  position: "relative",
  color: colors.primary,
  // marginRight: 3 * grid,
  width: 100,
  height: 100,
  // background: colors.border,
  // background: `linear-gradient(-30deg, ${"#F58C8D"} 0%, ${"#7860D8"} 100%)`,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // borderRadius: "50%",
  // boxShadow: shadows.small,
  // border: `4px solid red`,
  overflow: "hidden",
  // background: "red",

  [breakpoints.onlyS]: {
    display: "none",
    // marginRight: 0,
    // marginBottom: 2 * grid,
  },
});

const iconIconCSS = ({ theme: { colors } }) => ({
  position: "relative",
  width: 60,
  height: 60,
  background: colors.bg,
  // background: `linear-gradient(-30deg, ${"#F58C8D"} 0%, ${"#7860D8"} 100%)`,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  // border: `2px solid ${colors.border}`,
});

const textCSS = ({ theme: { colors, grid, breakpoints } }) => ({
  borderLeft: `1px solid ${colors.border}`,
  paddingLeft: 4 * grid,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // textAlign: "left",
  flex: 1,
  [breakpoints.onlyS]: {
    border: "none",
    padding: 0,
    // marginRight: 0,
    // marginBottom: 2 * grid,
  },
});
