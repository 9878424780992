import React, { useState, useCallback } from "react";
import { Card, View, Spacer, Input, Button, Text, LoadingOverlay } from "ollui";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Section } from "./section";
import { RiHomeSmile2Line } from "react-icons/ri";
// import Image from "../components/PreviewCompatibleImage";
// import screenshot from "../img/screenshot-page.png";

export const TestUsers = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!email) {
        return;
      }
      setIsLoading(true);
      addToMailchimp(email)
        .then((data) => {
          console.log(data);
          setIsSuccess(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [email]
  );

  return (
    <Section
      heading="Newsletter"
      subHeading="Sign up for updates"
      id="newsletter"
      extend={sectionCSS}
    >
      <View extend={rootCSS}>
        <Card
          as="form"
          onSubmit={handleSubmit}
          extend={containerCSS}
          autoComplete="on"
        >
          <Input
            placeholder="Email*"
            type="email"
            name="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <Spacer size={2} />
          <View
            as="textarea"
            extend={textareaCSS}
            placeholder="Why would you like to test"
            rows="5"
          /> */}
          <Spacer size={2} />
          <Button extend={{ marginLeft: "auto" }} onClick={handleSubmit}>
            Submit
          </Button>
          {isLoading && <LoadingOverlay />}
          {isSuccess && (
            <View extend={successCSS}>
              <RiHomeSmile2Line size={50} />
              <Spacer />
              <Text>Thank you!</Text>
            </View>
          )}
        </Card>
      </View>
    </Section>
  );
};

const sectionCSS = ({ theme: { breakpoints, grid } }) => ({
  // paddingTop: 45 * grid,
  zIndex: 0,
});

const rootCSS = ({ theme: { breakpoints, grid } }) => ({
  display: "flex",
  // gridTemplateColumns: "repeat(3, 1fr)",
  // gridGap: 8 * grid,
  flexDirection: "column",
  // background: "orange",
  justifyContent: "center",

  alignItems: "center",
  [breakpoints.onlyS]: {
    flexDirection: "column-reverse",
    // gridTemplateColumns: "1fr",
    // gridGap: 6 * grid,
  },
});

const containerCSS = ({ theme: { breakpoints, grid } }) => ({
  width: "100%",
  maxWidth: 400,
  overflow: "hidden",
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "flex-end",
});

const textareaCSS = ({ theme: { colors, shadows, grid, font, radius } }) => ({
  width: "100%",
  border: "none",
  boxShadow: shadows.small,
  border: `1px solid ${colors.border}`,
  padding: 2 * grid,
  fontFamily: font,
  borderRadius: radius,
  fontSize: 14,
  color: colors.body,
});

const successCSS = ({ theme: { colors } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  // background: "hotpink",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: colors.bg,

  "& > svg": {
    fill: colors.green,
  },
});
