import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { View, Text, Button, Spacer } from "ollui";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout";
// import BlogRoll from "../components/BlogRoll";
import { Features, TriangleBottom, Download, TestUsers } from "../sections";
import Image from "../components/PreviewCompatibleImage";

// import screenshot from "../img/screenshot-page.png";
// import hero from "../img/hero.svg";
export const IndexPageTemplate = ({ screenshot, title, subheading }) => (
  <View extend={rootCSS}>
    <View extend={heroCSS}>
      <Image
        imageInfo={screenshot}
        extend={screenshotImageCSS}
        objectFit="contain"
      />
      <TriangleBottom />
      <View extend={heroContentCSS}>
        <Text as="h1" variant="title" bold extend={{ maxWidth: 800 }}>
          {title}
        </Text>
        <Spacer size={4} />
        <Text as="h3" variant="medium" extend={{ maxWidth: 600 }}>
          {subheading}
        </Text>
        <Spacer size={4} />
        {/* <View> */}
        <Button
          as={AnchorLink}
          to="/#newsletter"
          extend={{ textDecoration: "none" }}
        >
          Sign up for newsletter
        </Button>
        <Spacer size={2} />
        <Button
          as={AnchorLink}
          to="/#download"
          extend={{ textDecoration: "none" }}
        >
          Download app
        </Button>
        {/* </View> */}
        {/* <Spacer size={6} />
        <View
          as="a"
          href="https://app.taltrast.com/login"
          extend={{ textDecoration: "none" }}
        >
          <Button>Start your free trial</Button>
        </View>
        <Spacer size={2} />
        <Text diffuse variant="small">
          21 days trial.
        </Text>
        <Spacer />
        <Text diffuse variant="small">
          No credit card required.
        </Text> */}
      </View>
      {/* <View as="img" src={hero} extend={heroImageCSS} /> */}
    </View>
    {/* <Screenshot screenshot={screenshot} /> */}
    <Features />
    <Download />
    <TestUsers />
    {/* <Form /> */}
    {/* <Pricing /> */}
    {/* <FAQ /> */}
    {/* <StartTrial /> */}
  </View>
);

const heroCSS = ({ theme: { breakpoints, grid } }) => ({
  position: "relative",
  width: "100%",
  // overflowX: "visible",
  // height: "75vh",
  // paddingLeft: 4 * grid,
  // paddingRight: 4 * grid,

  // [breakpoints.onlyS]: {
  //   // height: "75vh",
  //   paddingLeft: 2 * grid,
  //   paddingRight: 2 * grid,
  //   paddingTop: 4 * grid,
  // },
});

const heroContentCSS = ({ theme: { breakpoints, grid } }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  // right: 0,
  // right: -4 * grid,
  // background: "hotpink",
  width: "40%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // paddingTop: "8%",
  alignItems: "flex-start",
  padding: 4 * grid,
  textAlign: "left",

  [breakpoints.onlyS]: {
    // position: "relative",
    // height: "85vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // textAlign: "center",
    paddingTop: 0,
    paddingBottom: 64,
  },
});

const rootCSS = ({ theme: { grid, layout } }) => ({
  // padding: 4 * grid,
  paddingTop: 0,
  maxWidth: layout.maxContentWidth,
  margin: "0 auto",
});

const screenshotCSS = ({ theme: { colors, grid } }) => ({
  position: "relative",
  width: "100%",
  maxWidth: 800,
  borderRadius: 50,
  overflow: "hidden",
  margin: "0 auto",
  marginTop: 4 * grid,
  border: `5px dotted ${colors.border}`,
  // padding: 4 * grid,
});

const screenshotImageCSS = ({ theme: { colors, grid, breakpoints } }) => ({
  position: "relative",
  width: "100%",
  marginLeft: "auto",
  height: "75vh",
  // objectFit: 'cover',
  // objectPosition: '20% 50%'

  [breakpoints.onlyS]: {
    marginTop: 60,
    width: "100%",
    height: "75vh",
    opacity: 0.2,
  },
});

IndexPageTemplate.propTypes = {
  // screenshot: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  // console.log(frontmatter.screenshot);

  return (
    <Layout>
      <IndexPageTemplate
        screenshot={frontmatter.screenshot}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        screenshot {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        description
      }
    }
  }
`;
